import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2014 - 2018",
      title: "Computer Science",
      place: "Qarshi University",
      desc: "Bachelor of Science in Computer Science.",
    },
    {
      yearRange: "2010 - 2012",
      title: "ICS",
      place: "Govt. Degree College",
      desc: "Intermediate with ICS.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2012 - 2013",
      title: "Sr. Software Engineer",
      place: "Outgive",
      desc: "Worked as team lead and Full Stack Developer. Managed system requirements & plannings to provide better user experience in the product.",
    },
    {
      yearRange: "2014 - 2016",
      title: "Sr. Software Engineer",
      place: "Invozone",
      desc: "Worked as Sr. Full Stack Developer. Converted desgines to responsive layouts, Wrote secure APIs and perfect integrations.",
    },
    {
      yearRange: "2018 - 2020",
      title: "Software Engineer",
      place: "Ranglerz",
      desc: "Started working as frontend developer, but later got projects as backend and full stack developer as well.",
    },
    {
      yearRange: "2016 - Continued",
      title: "Software Engineer",
      place: "Freelance",
      desc: "Started working as internee frontend developer and now sometimes work for clients as senior software engineer or tech lead.",
    },
  ];

  const skills = [
    {
      name: "HTML5/CSS5",
      logo: "images/skills/html5.png"
    },
    {
      name: "React JS",
      logo: "images/skills/react.png"
    },
    {
      name: "JavaScript",
      logo: "images/skills/javascript.webp"
    },
    {
      name: "Angular Js",
      logo: "images/skills/angular.png"
    },
    {
      name: "Ionic",
      logo: "images/skills/ionic.png"
    },
    {
      name: "React Native",
      logo: "images/skills/react.png"
    },
    {
      name: "Bootstrap5",
      logo: "images/skills/bootstrap.svg"
    },
    {
      name: "Tailwind",
      logo: "images/skills/tailwind.png"
    },
    {
      name: "Bulma",
      logo: "images/skills/bulma.png"
    },
    {
      name: "Jquery",
      logo: "images/skills/jquery.png"
    },
    {
      name: "AJAX",
      logo: "images/skills/jquery.png"
    },
    {
      name: "Typescript",
      logo: "images/skills/typescript.png"
    },
    {
      name: "PHP",
      logo: "images/skills/php.png"
    },
    {
      name: "Laravel",
      logo: "images/skills/laravel.png"
    },
    {
      name: "Node JS",
      logo: "images/skills/node.png"
    },
    {
      name: "Nest JS",
      logo: "images/skills/nest.png"
    },
    {
      name: "Meteor JS",
      logo: "images/skills/meteor.png"
    },
    {
      name: "MySQL",
      logo: "images/skills/mysql.png"
    },
    {
      name: "MongoDB",
      logo: "images/skills/mongodb.svg"
    },
    {
      name: "PostgreSQL",
      logo: "images/skills/postgress.png"
    },
    {
      name: "Github",
      logo: "images/skills/github.png"
    }

  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            <div className="row gx-5">
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div className="col-md-6">
                  <div
                    key={index}
                    className={
                      "bg-white  rounded p-4 mb-4 " +
                      (darkTheme ? "bg-dark" : "bg-white border")
                    }
                  >
                    <p className="badge bg-primary text-2 fw-400">
                      {value.yearRange}
                    </p>
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                    <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                      {value.desc}
                    </p>
                  </div>
                </div>
              ))}
              <div className="col-md-6">

              </div>
            </div> 
          </div>
          {/* My Experience */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            <div className="row gx-5">
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div className="col-md-6">
                  <div
                    key={index}
                    className={
                      "bg-white  rounded p-4 mb-4 " +
                      (darkTheme ? "bg-dark" : "bg-white border")
                    }
                  >
                    <p className="badge bg-primary text-2 fw-400">
                      {value.yearRange}
                    </p>
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                    <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                      {value.desc}
                    </p>
                  </div>
                </div>
              ))}
              </div>
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-2 skillsBox">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <>
                <div className="col-md-3">
                  <div className="skillBox">
                    <img src={skill?.logo} />
                    {skill.name}{" "}
                  </div>
                </div>
              </>
              // <div key={index} className="col-md-6">
              //   <p
              //     className={
              //       " fw-500 text-start mb-2 " +
              //       (darkTheme ? "text-light" : "text-dark")
              //     }
              //   >
              //     {skill.name}{" "}
              //     <span className="float-end">{skill.percent}%</span>
              //   </p>
              //   <div
              //     className={
              //       "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
              //     }
              //   >
              //     <div
              //       className="progress-bar"
              //       role="progressbar"
              //       style={{ width: skill.percent + "%" }}
              //       aria-valuenow={skill.percent}
              //       aria-valuemin={0}
              //       aria-valuemax={100}
              //     />
              //   </div>
              // </div>
            ))}
        </div>
        {/* <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default Resume;
