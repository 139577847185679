import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    WEBSITE: "Websites",
    WEBAPP: "Web-Applications",
    MOBAPP: "Mobile-Applications",
  };

  const projectsData = [
    {
      title: "Prop Firm Funds Competitions",
      projectInfo:
        "This is a learning platform where users creates accounts, follow few steps and gets free demo and training accounts to learn trading basics.",
      technologies: "PHP, Bootstrap, Javascript, Laravel Jquery, AJAX, HTML, CSS, Zoom Integration",
      industry: "Trading",
      url: {
        name: "www.mypropfirmcompetition.com",
        link: "https://mypropfirmcompetition.com",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/portfolio/propfirms/image1.png",
      sliderImages: [
        "images/portfolio/propfirms/image2.png",
        "images/portfolio/propfirms/image3.png",
      ],
      categories: [filters.WEBSITE, filters.WEBAPP],
    },
    {
      title: "Prop Firm Funds Competitions - (React Version)",
      projectInfo:
        "This is a learning platform where users creates accounts, follow few steps and gets free demo and training accounts to learn trading basics. Additionally, it will allow users to buy challenges from this portal and connect to the Meta Traders API and view their statistics using this portal. ",
      technologies: "PHP, Laravel, Javascript, React , AntD, HTML, CSS",
      industry: "Trading",
      url: {
        name: "www.52.45.49.35:8081",
        link: "http://52.45.49.35:8081/",
      },
      thumbImage: "images/portfolio/propfirms/image4.png",
      sliderImages: [
        "images/portfolio/propfirms/image5.png",
        "images/portfolio/propfirms/image6.png",
      ],
      categories: [filters.WEBSITE, filters.WEBAPP],
    },
    {
      title: "Balloon Game",
      projectInfo:
        "Simple Arabic letter game to make kids learn the basics of Arabic.",
      technologies: "Bootstrap, Javascript, NodeJS Jquery, AJAX, HTML, CSS",
      industry: "Education",
      url: {
        name: "www.arabic-balloon-game.web.app/",
        link: "https://arabic-balloon-game.web.app/",
      },
      thumbImage: "images/portfolio/ballon/image1.png",
      sliderImages: [
        "images/portfolio/ballon/image2.png",
        "images/portfolio/ballon/image3.png",
      ],
      categories: [filters.WEBSITE],
    },
    {
      title: "Right Spouse",
      projectInfo:
        "A site that provides users a platform to create their profiles to find their life partners.",
      technologies: "Laravel, Bootstrap, Javascript, Jquery, AJAX, HTML, CSS",
      industry: "Matrimonial",
      url: {
        name: "www.rightspouse.com/",
        link: "https://rightspouse.com/",
      },
      thumbImage: "images/portfolio/rightspouse/image1.png",
      sliderImages: [
        "images/portfolio/rightspouse/image2.png",
        "images/portfolio/rightspouse/image3.png",
        "images/portfolio/rightspouse/image4.png",
      ],
      categories: [filters.WEBSITE],
    },
    {
      title: "Joompa",
      projectInfo:
        "It's a fitness AI mobile app. Users can avail the features of training sessions from experts or can perform their own workouts generated by the app.",
      technologies: "Ionic, Angular",
      industry: "Health",
      url: {
        name: "Available on Apple Store",
        link: "https://apps.apple.com/us/app/joompa/id1247281034?ls=1",
      },
      thumbImage: "images/portfolio/joompa/image3.webp",
      sliderImages: [
        "images/portfolio/joompa/image2.webp",
        "images/portfolio/joompa/image1.png",
      ],
      categories: [filters.MOBAPP],
    },
    {
      title: "Milan Tailors",
      projectInfo:
        "A platform that lets the users customize suits, slacks, shirts, vests and jackets.",
      technologies: "CodeIgniter, Bootstrap, Javascript, Jquery, AJAX, HTML, CSS",
      industry: "Fashion",
      url: {
        name: "www.milantailors.com/",
        link: "https://milantailors.com/",
      },
      thumbImage: "images/portfolio/milantailors/image1.png",
      sliderImages: [
        "images/portfolio/milantailors/image2.png",
        "images/portfolio/milantailors/image3.png",
      ],
      categories: [filters.WEBSITE],
    },
    {
      title: "Medicall",
      projectInfo:
        "Website where people can find doctors nearby them and can see complete history, education and reviews of each doctors and many more other features.",
      technologies: "PHP, Laravel, HTML5, CSS4, Bootstrap, AJAX, Javascript, Jquery",
      industry: "Healthcare",
      url: {
        name: "www.medicall.pk/",
        link: "https://medicall.pk/",
      },
      thumbImage:"images/portfolio/medicall/image1.png",
      sliderImages: [
        "images/portfolio/medicall/image2.png",
        "images/portfolio/medicall/image3.png",
      ],
      categories: [filters.WEBSITE],
    },
    {
      title: "My BOGO AI App",
      projectInfo:
        "A portal where people can come and find Events and BOGOs nearby their location in USA.",
      technologies: "React Native, NodeJS, MongoDB",
      industry: "Information",
      url: {
        name: "www.mybogoai.com/",
        link: "https://mybogoai.com/",
      },
      thumbImage:"images/portfolio/bogo/image4.png",
      sliderImages: [
        "images/portfolio/bogo/image5.png",
        "images/portfolio/bogo/image6.png",
      ],
      categories: [filters.MOBAPP],
    },
    {
      title: "My BOGO AI",
      projectInfo:
        "A portal where people can come and find Events and BOGOs nearby their location in USA.",
      technologies: "ReactJS, NodeJS, MongoDB",
      industry: "Information",
      url: {
        name: "www.mybogoai.com/",
        link: "https://mybogoai.com/",
      },
      thumbImage:"images/portfolio/bogo/image1.png",
      sliderImages: [
        "images/portfolio/bogo/image2.png",
        "images/portfolio/bogo/image3.png",
      ],
      categories: [filters.WEBSITE],
    },
    {
      title: "CSA",
      projectInfo:
        "A portal where officers can collaborate with each other and remain in touch with latest news.",
      technologies: "Laravel, Bootstrap, Javascript, Jquery, AJAX, HTML, CSS",
      industry: "Information",
      url: {
        name: "www.csa.gov.pk",
        link: "https://csa.gov.pk/",
      },
      thumbImage:"images/portfolio/csa/image1.png",
      sliderImages: [
        "images/portfolio/csa/image2.png",
      ],
      categories: [filters.WEBAPP],
    },
    {
      title: "Holistic DG",
      projectInfo:
        "A website and mobile application to buy items online.",
      technologies: "Laravel, Bootstrap, Javascript, Jquery, AJAX, HTML, CSS, Android",
      industry: "Ecommerce",
      url: {
        name: "Available on Store",
        link: "https://play.google.com/store/apps/details?id=com.holistic.holistic&hl=en&gl=US",
      },
      thumbImage:"images/portfolio/holisticdg/image1.png",
      sliderImages: [
        "images/portfolio/holisticdg/image2.png",
        "images/portfolio/holisticdg/image3.png",
      ],
      categories: [filters.WEBSITE, filters.MOBAPP],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">{project?.industry}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
